import {createApp} from 'vue';
import {
    Popup, Button, Icon, Tag, Col, Row, Image, Collapse, CollapseItem, DropdownMenu, DropdownItem,
    PullRefresh, List, Grid, GridItem, Form, CellGroup, Sticky, Dialog, Notify, DatetimePicker, NoticeBar,NavBar,
    Calendar, Search, Picker, Switch, Uploader, Radio, RadioGroup, Cell, SwipeCell, Checkbox, CheckboxGroup, SubmitBar, Card,
    Pagination, Tab, Tabs, Stepper, CountDown, ActionBar, ActionBarIcon, ActionBarButton, Divider, Loading,Overlay, Step, Steps
} from 'vant';
import App from './App.vue';
import {router} from './router';
import Field from './components/vant/Field';
import sapi from './sapi';
import './commonfunc'
import '@vant/touch-emulator';
import {createPinia} from 'pinia'
import vConsole from './utils/vconsole'

import wx from 'weixin-js-sdk'
window.wx = wx;


const state = createPinia()

const app = createApp(App);
sapi.baseUrl = process.env.VUE_APP_API_BASE_URL;
app.config.globalProperties.$sapi = sapi;
app
    .use(router)
    .use(Popup)
    .use(Button)
    .use(NavBar)
    .use(Icon)
    .use(Tag)
    .use(Col)
    .use(Row)
    .use(Image)
    .use(PullRefresh)
    .use(List)
    .use(Grid)
    .use(GridItem)
    .use(Form)
    .use(Field)
    .use(CellGroup)
    .use(Calendar)
    .use(DatetimePicker)
    .use(Search)
    .use(Dialog)
    .use(Notify)
    .use(Picker)
    .use(Switch)
    .use(Uploader)
    .use(Radio)
    .use(Cell)
    .use(RadioGroup)
    .use(SwipeCell)
    .use(Checkbox)
    .use(CheckboxGroup)
    .use(SubmitBar)
    .use(Card)
    .use(Collapse)
    .use(CollapseItem)
    .use(Sticky)
    .use(DropdownMenu)
    .use(DropdownItem)
    .use(NoticeBar)
    .use(Pagination)
    .use(Tab)
    .use(Tabs)
    .use(Stepper)
    .use(CountDown)
    .use(ActionBar)
    .use(ActionBarIcon)
    .use(ActionBarButton)
    .use(Divider)
    .use(Loading)
    .use(Overlay)
    .use(Step)
    .use(Steps)
    .use(state)
    .mount('#app');
