import { Field } from 'vant';
import { getCurrentInstance, computed } from 'vue';

const component = Object.assign({}, Field);
const __setup = component.setup;
component.setup = function (props, context) {
    const instance = getCurrentInstance();
    const setupResult = __setup(props, context);
    const __formValue = instance.proxy.formValue;
    instance.proxy.formValue = computed(() => {
        if (props.value) return props.value;
        return __formValue.value;
    });
    return setupResult;
}
component.install = app => {
    app.component(component.name, component);
}

export default component;
