if (window.console == undefined) {
    window.console = { log: function () { } };
}

//检查对象是否为空
function isEmpty(value, allowEmptyString) {
    if(typeof value === 'number'){
        return false;
    }
    if(typeof (Ext) == "object"){
        return Ext.isEmpty(value,allowEmptyString);
    }
    return (value === null) || (value === undefined) || (!allowEmptyString ? value === '' : false) || ($.isArray(value) && value.length === 0) || $.isEmptyObject(value);
}
//判断函数是否为空
Function.prototype.checkIsFunction = true;
function isFunction(value) {
    return !!(value && value.checkIsFunction);
}

//对象格式化
function format(str, args) {
    if (arguments.length > 1) {
        var result = str;
        if (arguments.length == 2 && typeof(args) == "object") {
            for (var key in args) {
                var reg = new RegExp("({" + key + "})", "g");
                result = result.replace(reg, args[key]);
            }
        } else {
            for (var i = 1; i < arguments.length; i++) {
                if (arguments[i] == undefined) {
                    return "";
                } else {
                    reg = new RegExp("({[" + (i - 1) + "]})", "g");
                    result = result.replace(reg, arguments[i]);
                }
            }
        }
        return result;
    } else {
        return str;
    }
}

//深度拷贝
function deepCopy(obj) {
    if (Object.prototype.toString.call(obj) === '[object Array]') {
        var out = [], i = 0, len = obj.length;
        for (; i < len; i++) {
            out[i] = deepCopy(obj[i]);
        }
        return out;
    }
    if (typeof obj === 'object') {
        if (obj == null) {
            return null;
        }
        if (obj instanceof Date) {
            var copy = new Date();
            copy.setTime(obj.getTime());
            return copy;
        }
        out = {};
        for (i in obj) {
            out[i] = deepCopy(obj[i]);
        }
        return out;
    }
    return obj;
}

//获得防止伪造验证值
function getForgeryVerificationVal() {
    if (typeof (Ext) == "object") {
        var els = Ext.select("input[name = '__RequestVerificationToken']").elements;
        if (els.length == 0) return 'Illegal RequestVerification';
        return els[0].value;
    } else {
        return $("[name = '__RequestVerificationToken']").val();
    }
}


//检测密码强度
//密码至少8位且必须是字母大写,字母小写,数字,特殊字符中任意三个组合
var checkPasswordStrong = function (s) {
    if (s.length < 8) {
        return false;
    }
    var ls = 0;
    if (s.match(/([a-z])+/)) {
        ls++;
    }
    if (s.match(/([0-9])+/)) {
        ls++;
    }
    if (s.match(/([A-Z])+/)) {
        ls++;
    }
    if (s.match(/[^a-zA-Z0-9]+/)) {
        ls++;
    }
    if (ls < 3) {
        return false;
    }
    return true;
};

/*******时间对象相关功能 start********************************************************/
//添加时间
Date.prototype.dateAdd = function (interval, number) {
    var d = this;
    var k = {
        'y': 'FullYear',
        'q': 'Month',
        'm': 'Month',
        'w': 'Date',
        'd': 'Date',
        'h': 'Hours',
        'n': 'Minutes',
        's': 'Seconds',
        'ms': 'Milliseconds'
    };
    var n = {
        'q': 3,
        'w': 7
    };
    eval('d.set' + k[interval] + '(d.get' + k[interval] + '()+' + ((n[interval] || 1) * number) + ')');
    return d;
}
/* 计算两日期相差的日期年月日等 */
Date.prototype.dateDiff = function (interval, objDate2) {
    var d = this, i = {}, t = d.getTime(), t2 = objDate2.getTime();
    i['y'] = objDate2.getFullYear() - d.getFullYear();
    i['q'] = i['y'] * 4 + Math.floor(objDate2.getMonth() / 4) - Math.floor(d.getMonth() / 4);
    i['m'] = i['y'] * 12 + objDate2.getMonth() - d.getMonth();
    i['ms'] = objDate2.getTime() - d.getTime();
    i['w'] = Math.floor((t2 + 345600000) / (604800000)) - Math.floor((t + 345600000) / (604800000));
    i['d'] = Math.floor(t2 / 86400000) - Math.floor(t / 86400000);
    i['h'] = Math.floor(t2 / 3600000) - Math.floor(t / 3600000);
    i['n'] = Math.floor(t2 / 60000) - Math.floor(t / 60000);
    i['s'] = Math.floor(t2 / 1000) - Math.floor(t / 1000);
    return i[interval];
}
//日期格式化
Date.prototype.pattern = function (fmt) {
    var o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "h+": this.getHours() % 12 == 0 ? 12 : this.getHours() % 12, //小时
        "H+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        "S": this.getMilliseconds() //毫秒
    };
    var week = {
        "0": "\u65e5",
        "1": "\u4e00",
        "2": "\u4e8c",
        "3": "\u4e09",
        "4": "\u56db",
        "5": "\u4e94",
        "6": "\u516d"
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    }
    if (/(E+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, ((RegExp.$1.length > 1) ? (RegExp.$1.length > 2 ? "\u661f\u671f" : "\u5468") : "") + week[this.getDay() + ""]);
    }
    for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        }
    }
    return fmt;
}
//把字符串转化成日期 格式 2016-07-11 21:32:26
Date.prototype.parse = function (str) {
    var tempStrs = str.split(" ");
    var dateStrs = tempStrs[0].split("-");
    var year = parseInt(dateStrs[0], 10);
    var month = parseInt(dateStrs[1], 10) - 1;
    var day = parseInt(dateStrs[2], 10);
    if(!Ext.isEmpty(tempStrs[1])){
        var timeStrs = tempStrs[1].split(":");
        var hour = 0, minute = 0, second = 0;
        if (timeStrs.length > 0) {
            hour = parseInt(timeStrs[0], 10);
            minute = parseInt(timeStrs[1], 10);
            second = parseInt(timeStrs[2], 10);
        }
    }else{
        hour = 0;
        minute = 0;
        second = 0;
    }


    var date = new Date(year, month, day, hour, minute, second);
    return date;
}
//取消UTC的格式化
Date.prototype.toJSON = function () {
    return this.pattern('yyyy-MM-dd HH:mm:ss');
};

//part  'date':显示日期部分  'time':显示时间部分  'yearMonth':显示‘年-月’  'year':显示年  'datetime':全部显示，默认datetime 
var getDateFormatFunc = function (part) {
    return function (date) {
        if(isEmpty(date)){
            return '';
        }
        if (typeof (date) == "string") {
            var dateStr = date;
            date = new Date();
            date = date.parse(dateStr);
        }
        var format = 'yyyy-MM-dd HH:mm:ss';
        switch (part) {
            case 'date':
                format = 'yyyy-MM-dd';
                break;
            case 'time':
                format = 'HH:mm:ss';
                break;
            case 'yearMonth':
                format = 'yyyy-MM';
                break;
            case 'year':
                format = 'yyyy';
                break;
        }
        return date.pattern(format)
    };
};

//把整数转化成时间， interval 指定整数是什么类型
function cIntToDateTime(interval, number) {
    var date = new Date(0, 0, 0, 0, 0, 0, 0);
    return date.dateAdd(interval, number);
}
//public static String format(long ms) {//将秒数换算成x时x分x秒
//   int ss = 1000;
//int mi = ss * 60;
//int hh = mi * 60;
//int dd = hh * 24;
//long day = ms / dd;
//long hour = (ms - day * dd) / hh;
//long minute = (ms - day * dd - hour * hh) / mi;
//long second = (ms - day * dd - hour * hh - minute * mi) / ss;
//long milliSecond = ms - day * dd - hour * hh - minute * mi - second * ss;
//String strDay = day < 10 ? "0" + day : "" + day;
//String strHour = hour < 10 ? "0" + hour : "" + hour;
//String strMinute = minute < 10 ? "0" + minute : "" + minute;
//String strSecond = second < 10 ? "0" + second : "" + second;
//String strMilliSecond = milliSecond < 10 ? "0" + milliSecond : "" + milliSecond;
//strMilliSecond = milliSecond < 100 ? "0" + strMilliSecond : "" + strMilliSecond;
//return strDay + " " + strHour + ":" + strMinute + ":" + strSecond + " " + strMilliSecond;
//}
/*******时间对象相关功能  end********************************************************/

//生成 Guid
var guid = function () {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }

    var code = function () {
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
            s4() + '-' + s4() + s4() + s4();
    }
    return code().toUpperCase();
};

// 金额格式化
function moneyFormatByDecimals(num, decimals) {
    if (Ext.isEmpty(num) || Ext.isEmpty(Ext.String.trim(num.toString()))) {
        return Ext.emptyString;
    }

    var prefix = parseFloat(num) < 0 ? '-' : '';

    num = (Math.abs(num) || 0).toString();
    if (decimals == null) {
        decimals = 2;
    }

    num = roundFormat(num, decimals);
    var intVar = num.substring(0, num.indexOf('.'));
    var decimalVar = num.substring(num.indexOf('.'));

    var result = '';

    while (intVar.length > 3) {
        result = ',' + intVar.slice(-3) + result;
        intVar = intVar.slice(0, intVar.length - 3);
    }

    if (intVar) {
        result = intVar + result;
    }

    result += decimalVar;

    return '￥' + prefix + result;
}


function getUrlParam(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return decodeURIComponent(r[2]); return null;
}

//下载控件
var downLoadCmp = {
    getLoadDownUrl: function (text, url) {
        var me = downLoadCmp;
        if(me.isImage(url)){
            return format('<a href="javascript:void(0)" onclick = Ext.create(\'Extend.img.ImgViewerWin\').viewImg(\'{1}\');>{0}</a>', text, url);
        }else{
            return format('<a href="javascript:void(0)" onclick = window.open("{1}");>{0}</a>', text, url);
        }
    },
    getExtension: function (url) {
        if(Ext.isEmpty(url)) return Ext.emptyString;
        url = url.substring(url.lastIndexOf("?"))
        var reg = new RegExp("(^|&)ext=([^&]*)(&|$)");
        var r = url.substr(1).match(reg);
        var extension = '';
        if (r != null) {
            extension = decodeURIComponent(r[2]).toLowerCase();
        }
        return extension;
    },
    isImage: function (url) {
        var me = downLoadCmp;
        var extension = me.getExtension(url);
        switch (extension) {
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'bmp':
            case 'gif':
            case 'ico':
                return true;
            default:
                return false;
        }
    }
};