<template>
  <van-nav-bar
    :left-arrow="showBackButton == 'true' || showBackButton == true"
    :title="title"
  />
  <component :is="asyncContent"></component>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { NavBar } from "vant";

export default {
  props: {
    __content: Function,
    showBackButton: {
      type:[Boolean, String],
      default: true
    },
    title: {
      type: String,
      default: ''
    }
  },
  setup(props, { slots, attrs, emit }) {
    return {
      asyncContent: defineAsyncComponent(props.__content),
    };
  },
  components: {
    [NavBar.name]: NavBar,
  },
};
</script>

<style>
.van-nav-bar {
  padding-top: var(--status-bar-height);
}
</style>