<template>
  <van-nav-bar v-if="showNavBar" :title="$route.meta.title"
    :left-text="$route.meta.actionbar.showBackButton ? '刷新' : ''" right-text=""
    :left-arrow="$route.meta.actionbar.showBackButton" @click-left="onClickLeft" placeholder fixed />

  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" v-if="$route.meta.keepAlive" />
    </keep-alive>
    <component :is="Component" v-if="!$route.meta.keepAlive" />
  </router-view>

</template>

<script>
export default {
  computed: {
    showNavBar: function () {
      return this.$route?.meta?.actionbar?.enable;
    },
  },
  methods: {
    onClickLeft: function () {
      location.reload();
      /* history.go(0);*/
      /*  this.$router.back();*/
    },
  }
}
</script>

<style>
:root {
  --van-font-size-xs: 12px;
  --van-font-size-sm: 14px;
  --van-font-size-md: 16px;
  --van-font-size-lg: 18px;
  --van-dropdown-menu-title-font-size: 24px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--van-font-size-md);
  background: #f8f8f8;

  /* vant 主题变量定制 */
  /* --van-primary-color: #4d5e9e; */
  --van-nav-bar-icon-color: var(--van-text-color);
  --van-nav-bar-text-color: var(--van-text-color);
  --van-nav-bar-height: 38px;
}

.van-field__label {
  flex: none;
  box-sizing: border-box;
  width: var(--van-field-label-width);
  margin-right: var(--van-field-label-margin-right);
  font-weight: 445;
  color: #000000;
  text-align: left;
  word-wrap: break-word;
}

.van-dropdown-menu__title {
  position: relative;
  box-sizing: border-box;
  max-width: 100%;
  padding: var(--van-dropdown-menu-title-padding);
  color: var(--van-dropdown-menu-title-text-color);
  font-size: var(--van-dropdown-menu-title-font-size);
  line-height: var(--van-dropdown-menu-title-line-height);
  font-weight: bold;
}

.van-dropdown-menu__title:after {
  position: absolute;
  top: 10%;
  right: -5.06667vw;
  margin-top: -1.33333vw;
  border: 2.5vw solid;
  border-color: transparent transparent black black;
  transform: rotate(-45deg);
  opacity: .8;
  content: "";
}

.van-dropdown-menu__item {
  background-color: rgba(0, 122, 255, 0.6);
  ;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  min-width: 0;
}

.van-cell:after {
  color: #007aff;
  position: absolute;
  box-sizing: border-box;
  content: " ";
  pointer-events: none;
  right: var(--van-padding-md);
  bottom: 0;
  left: var(--van-padding-md);
  border-bottom: 1px solid #000;
  transform: scaleY(.5);
}

.amap-scalecontrol {
  left: 70px !important;
}

.amap-logo {
  right: 0 !important;
  left: auto !important;
  display: none !important;
}

.amap-copyright {
  right: 70px !important;
  left: auto !important;
  opacity: 0 !important;
}

.van-nav-bar {
  padding-top: var(--status-bar-height);
}

.border-radius-round {
  border-radius: 999999px;
}

.fs8 {
  font-size: 8px;
}

.fs10 {
  font-size: 10px;
}

.fs12 {
  font-size: 12px;
}

.fs16 {
  font-size: 16px;
}

.tag {
  font-size: 10px;
  border-radius: 9999px;
  background: #e9bd93;
  margin-left: 10px;
  line-height: 12px;
  color: var(--van-primary-color);
  padding: 1.4px 10px;
}

#__vconsole {
  display: none;
}

#__vconsole.show {
  display: block;
}
</style>
