import { createRouter, createWebHashHistory } from 'vue-router'
import sapi from './sapi'
import PageContainer from './components/PageContainer'
import _ from 'lodash'

// 定义路由
const routes = [
	{
		path: '/',
		component: () => import('./views/Main'),
		children: [
			{
				path: '/',
				redirect: '/receiveScan'
				// name: 'home',
				// component: () => import('./views/tabbar/Home'),
				// meta: {
				// 	title: '首页',
				// 	actionbar: {
				// 		enable: false,
				// 	},
				// },
				// statusBarStyle: 'dark',
			},
			{
				path: 'car',
				component: () => import('./views/tabbar/Car'),
				meta: {
					title: '车辆',
					actionbar: {
						enable: false,
					},
					statusBarStyle: 'dark',
				},
			},
			{
				path: 'car2',
				component: () => import('./views/tabbar/Car2'),
				meta: {
					title: '上报',
					actionbar: {
						enable: false,
					},
					statusBarStyle: 'dark',
				},
			},
			{
				path: 'exception2',
				component: () => import('./views/tabbar/Exception2'),
				meta: {
					title: '异常上报',
					actionbar: {
						enable: false,
					},
					statusBarStyle: 'dark',
				},
			},
			{
				path: 'RQF',
				component: () => import('./views/tabbar/RQF'),
				meta: {
					title: '询价单',
					actionbar: {
						enable: false,
					},
					statusBarStyle: 'dark',
				},
			},
			{
				path: 'Queue',
				component: () => import('./views/tabbar/Queue'),
				meta: {
					title: '叫号入场',
					actionbar: {
						enable: false,
					},
					statusBarStyle: 'dark',
				},
			},
			{
				path: 'person',
				component: () => import('./views/tabbar/Person'),
				meta: {
					title: '人员',
					actionbar: {
						enable: false,
					},
					statusBarStyle: 'dark',
				},
			},
			{
				path: 'my',
				component: () => import('./views/tabbar/My'),
				meta: {
					title: '我的',
					actionbar: {
						enable: false,
					},
					statusBarStyle: 'dark',
				},
			},
			{
				path: '/rummage',
				component: () => import('./views/rummage/Rummage'),
				meta: {
					title: '翻包管理',
					actionbar: {
						showBackButton: true,
					},
				},
			},
			// 资产盘点
			{
				path: '/receiveScan',
				component: () => import('./views/receiveScan/receiveScan'),
				meta: {
					title: '资产盘点',
					actionbar: {
						showBackButton: false,
					},
				},
			},
			{
				path: '/receiveCheck',
				component: () => import('./views/receiveScan/ReceiveCheck'),
				meta: {
					title: '资产盘点',
					actionbar: {
						showBackButton: false,
					},
				},
			},
			{
				path: '/receiveCheckInfo',
				component: () => import('./views/receiveScan/ReceiveCheckInfo'),
				meta: {
					title: '资产盘点单',
					actionbar: {
						showBackButton: false,
					},
				},
			},
			{
				path: '/scanPackageCode',
				component: () => import('./views/scanCheck/ScanPackageCode'),
				meta: {
					title: '箱号扫描',
					actionbar: {
						showBackButton: true,
					},
				},
			},
			{
				path: '/scanPick',
				component: () => import('./views/pickOrder/ScanPick'),
				meta: {
					title: '扫描捡配',
					actionbar: {
						showBackButton: true,
					},
				},
			},
			{
				path: '/pdaRummage',
				component: () => import('./views/pdaRummage/pdaRummage'),
				meta: {
					title: 'PDA翻包管理',
					actionbar: {
						showBackButton: true,
					},
				},
			},
			{
				path: '/pdaRummageNew',
				component: () => import('./views/pdaRummage/pdaRummageNew'),
				meta: {
					title: 'PDA新翻包管理',
					actionbar: {
						showBackButton: true,
					},
				},
			},
			{
				path: '/scanCheckNew',
				component: () => import('./views/scanCheck/ScanCheckNew'),
				meta: {
					title: '扫描核验',
					actionbar: {
						showBackButton: true,
					},
				},
			},
			{
				path: '/bindPackageCode',
				component: () => import('./views/receiveScan/BindPackageCode'),
				meta: {
					title: '绑定箱号',
					actionbar: {
						showBackButton: true,
					},
				},
			},
			{
				path: '/loadcarorder',
				component: () => import('./views/pickOrder/Loadcarorder'),
				meta: {
					title: '扫描发货',
					actionbar: {
						showBackButton: true,
					},
				},
			},
			{
				path: '/loadcarorderNew',
				component: () => import('./views/pickOrder/LoadcarorderNew'),
				meta: {
					title: '新发货管理',
					actionbar: {
						showBackButton: true,
					},
				},
			},
			{
				name: 'lcd',
				path: '/loadcarorderNewDetail',
				component: () => import('./views/pickOrder/loadCarOrderNewDetail'),
				meta: {
					title: '装车明细',
					actionbar: {
						showBackButton: true,
					},
				},
			},
			{
				path: '/scanPackageCode',
				component: () => import('./views/scanCheck/ScanPackageCode'),
				meta: {
					title: '箱号扫描',
					actionbar: {
						showBackButton: true,
					},
				},
			},
		],
		meta: {
			actionbar: {
				enable: false,
			},
		},
	},
	{
		path: '/scanMaterial',
		component: () => import('./views/scanMaterial'),
		meta: {
			title: '扫描物料',
			actionbar: {
				enable: false,
			},
			statusBarStyle: 'dark',
		},
	},
	{
		path: '/register',
		component: () => import('./views/Register'),
		meta: {
			title: '我的',
			actionbar: {
				enable: false,
			},
			statusBarStyle: 'dark',
		},
	},
	{
		path: '/visitorReserve',
		name: 'visitorReserve',
		component: () => import('./views/visitor/VisitorReserve'),
		meta: {
			title: '访客预约',
			actionbar: {
				showBackButton: true,
			},
		},
	},
	{
		path: '/constructionReserve',
		name: 'constructionReserve',
		component: () => import('./views/visitor/ConstructionReserve'),
		meta: {
			title: '施工预约',
			actionbar: {
				showBackButton: true,
			},
		},
	},
	{
		path: '/wf',
		name: 'wf',
		component: () => import('./views/workflow/WorkFlow'),
	},
	{
		path: '/wfLog',
		name: 'wfLog',
		component: () => import('./views/workflow/WorkFlowLog'),
		meta: {
			title: '审批记录',
			actionbar: {
				showBackButton: true,
			},
		},
	},
	{
		path: '/visitorReserveList',
		component: () => import('./views/visitor/VisitorReserveList'),
		meta: {
			title: '预约信息',
			actionbar: {
				showBackButton: true,
			},
		},
	},
	{
		path: '/vehicleReserveList',
		component: () => import('./views/vehicle/VehicleReserveList'),
		meta: {
			title: '预约信息',
			actionbar: {
				showBackButton: true,
			},
		},
	},
	{
		path: '/visitorCarList',
		name: 'visitorCarList',
		component: () => import('./views/visitor/VisitorCarList'),
		meta: {
			title: '车辆信息',
			actionbar: {
				showBackButton: true,
			},
		},
	},
	{
		path: '/visitorCar',
		name: 'visitorCar',
		component: () => import('./views/visitor/VisitorCar'),
		meta: {
			title: '车辆信息',
			actionbar: {
				showBackButton: true,
			},
		},
	},
	{
		path: '/commitRummage',
		component: () => import('./views/pdaRummage/commitRummage'),
		meta: {
			title: '提交翻包',
			actionbar: {
				showBackButton: true,
			},
		},
	},
	{
		path: '/rummage',
		component: () => import('./views/rummage/Rummage'),
		meta: {
			title: '翻包管理',
			actionbar: {
				showBackButton: true,
			},
		},
	},
	{
		path: '/receiveScan',
		component: () => import('./views/receiveScan/receiveScan'),
		meta: {
			title: '收货扫描',
			actionbar: {
				showBackButton: true,
			},
		},
	},
	{
		path: '/receiveCheck',
		component: () => import('./views/receiveScan/ReceiveCheck'),
		meta: {
			title: '扫描核验',
			actionbar: {
				showBackButton: true,
			},
		},
	},
	{
		path: '/test',
		component: () => import('./views/test/QRReaderTest'),
		meta: {
			title: 'test',
			actionbar: {
				showBackButton: true,
			},
		},
	},
	{
		path: '/commonVehicleReserve',
		name: 'commonVehicleReserve',
		component: () => import('./views/vehicle/CommonVehicleReserve'),
		meta: {
			title: '派车单信息',
			actionbar: {
				showBackButton: true,
			},
		},
	},
	{
		path: '/ExceptionReserve2',
		name: 'ExceptionReserve2',
		component: () => import('./views/vehicle/ExceptionReserve2'),
		meta: {
			title: '派车单异常上报信息',
			actionbar: {
				showBackButton: true,
			},
		},
	},
	{
		path: '/RFQReserve',
		name: 'RFQReserve',
		component: () => import('./views/RQF/RFQReserve'),
		meta: {
			title: '询价单填报',
			actionbar: {
				showBackButton: true,
			},
		},
	},
	{
		path: '/chemicalVehicleReserve',
		name: 'chemicalVehicleReserve',
		component: () => import('./views/vehicle/ChemicalVehicleReserve'),
		meta: {
			title: '货车订单号预约',
			actionbar: {
				showBackButton: true,
			},
		},
	},
	{
		path: '/signIn2',
		name: 'signIn2',
		component: () => import('./views/vehicle/SignIn'),
		meta: {
			title: '危化签到',
			actionbar: {
				showBackButton: true,
			},
		},
	},
	{
		path: '/exam',
		component: () => import('./views/exam/Exam'),
		meta: {
			title: '安全考试',
			actionbar: {
				showBackButton: true,
			},
		},
	},
	{
		path: '/visitorInfo',
		component: () => import('./views/visitor/VisitorInfo'),
		meta: {
			title: '身份信息',
			actionbar: {
				showBackButton: true,
			},
		},
	},
	{
		path: '/printWay', //打印机客户端页面
		component: () => import('./views/print/PrintWay'),
		meta: {
			actionbar: {
				enable: false,
			},
		},
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('./views/Login'),
		meta: {
			actionbar: {
				enable: false,
			},
		},
	},
	{
		path: '/register',
		name: 'register',
		component: () => import('./views/Register'),
		meta: {
			title: '注册',
			actionbar: {
				showBackButton: true,
			},
			login: false,
		},
	},
	{
		path: '/passwordChange',
		name: 'passwordChange',
		component: () => import('./views/PasswordChange'),
		meta: {
			title: '重置密码',
			actionbar: {
				showBackButton: true,
				enable: true,
			},
			login: false,
		},
	},
	{
		path: '/setting',
		name: 'setting',
		component: () => import('./views/Setting'),
		meta: {
			title: '设置',
			actionbar: {
				showBackButton: true,
			},
		},
	},
	/*{
        path: '/course',
        name: 'course',
        component: () => import('./views/course/ReserveCourseIF'),
        meta: {
            title: '教程',
            actionbar: {
                showBackButton: true
            }
        }
    }, */ {
		path: '/courseVideo',
		name: 'courseVideo',
		component: () => import('./views/course/CourseVideo'),
		meta: {
			title: '操作教程',
			actionbar: {
				showBackButton: true,
			},
		},
	},
	{
		path: '/playVideo',
		name: 'playVideo',
		component: () => import('./views/course/PlayVideo'),
		meta: {
			title: '',
			actionbar: {
				showBackButton: true,
			},
		},
	},
	{
		path: '/qrtest',
		name: 'qrtest',
		component: () => import('./views/test/QRReaderTest'),
		meta: {
			title: 'qrtest',
			actionbar: {
				showBackButton: true,
			},
		},
	},
	{
		path: '/test',
		name: 'test',
		component: () => import('./views/SignNameCanvas'),
		meta: {
			title: 'test',
			actionbar: {
				showBackButton: true,
			},
		},
	},
	{
		path: '/radioTest',
		name: 'radioTest',
		component: () => import('./views/test/Radio'),
		meta: {
			title: '',
			actionbar: {
				showBackButton: true,
			},
		},
	},
	{
		path: '/imageSelect',
		name: 'imageSelect',
		component: () => import('./views/dynamic/ImageSelect'),
		meta: {
			title: '',
			actionbar: {
				showBackButton: true,
			},
		},
	},
	{
		path: '/test2',
		name: 'test2',
		component: () => import('./views/test/CheckEditGird'),
		meta: {
			title: 'test2',
			actionbar: {
				showBackButton: true,
			},
		},
	},
	{
		path: '/df',
		name: 'df',
		component: () => import('./views/dynamic/DynamicForm'),
		meta: {
			title: '参数',
			actionbar: {
				showBackButton: false,
			},
		},
	},
	{
		path: '/upload',
		component: () => import('./views/Upload'),
	},
	{
		path: '/test/nativePlugins',
		component: () => import('./views/test/NativePlugins'),
	},
	{
		path: '/map',
		component: () => import('./views/map/Map'),
	},
]

const handleNavBarData = routes => {
	for (const item of routes) {
		if (item.children && item.children.length) handleNavBarData(item.children)
		if (!item.meta) {
			item.meta = {}
		}
		if (_.isEmpty(item.meta.actionbar)) {
			item.meta.actionbar = {
				showBackButton: true,
				enable: true,
			}
		} else {
			item.meta.actionbar = Object.assign(
				{
					showBackButton: true,
					enable: true,
				},
				item.meta.actionbar
			)
		}
		if (item.meta.actionbar.enable == undefined) {
			console.log(item.path)
			debugger
		}
	}
}
handleNavBarData(routes)

const router = createRouter({
	routes,
	history: createWebHashHistory(),
})

const defulatStatusBarStyle = 'light'
const defaultLogin = false

router.beforeEach(async (to, from) => {
	// if ((to.meta.login ?? defaultLogin) && !sapi.isLogin()) {
	//     // 从首页进入和其他页进入到登陆页时会有后退按钮的差异
	//     router.push({ path: '/login', query: { canback: to.path != '/' } });
	//     return false;
	// }

	updateTitle(to, from)
	updateStatusBarStyle(to, from)
})

function updateTitle(to, from) {
	document.title = '富日物流'
}

function updateStatusBarStyle(to, from) {
	if (!window.Capacitor) return
	const statusBarStyle = to.meta.statusBarStyle ?? defulatStatusBarStyle
	Capacitor.Plugins.StatusBar.setStyle({ style: statusBarStyle.toUpperCase() })
}

export { router }
